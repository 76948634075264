// import Vue from 'vue'
//Store
import { FilesNameSpace, FilesActionTypes, FilesGetterTypes } from '@/store/files/types';
//Helpers
import { notifyDefaultError } from '../helpers/notifications';

const _onFileUpload_ = {
    computed: {
        _loadedFile_() {
            return this.$store.getters[`${FilesNameSpace}/${FilesGetterTypes.GetLoadedFiles}`]
        },
    },
    methods: {
        async _onFileUpload_({ field }) {
            try {
                const form_data = new FormData()
                form_data.append('files', this[field][0]) //Переменная, в которую инпутом кладется файл 
                await this.$store.dispatch(`${FilesNameSpace}/${FilesActionTypes.AddFile}`, { files: form_data })
            } catch (error) {
                notifyDefaultError({ error })
            }
        }
    }
}

export {
    _onFileUpload_,
}