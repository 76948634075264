<template lang="pug">
    div
        label.text-xs.mb-2.block.text-left {{ label }}
        input.block( ref="file" type="file" @change="onFiles")
</template>

<script>
export default {
    name: "ChooseFile",
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: Array,
            default: () => [],
        }
    },
    methods: {
        onFiles({ target }) {
            this.$emit('value', target.files)
        }
    },
    watch: {
        value: {
            handler(val) {
                if (!val.length) this.$refs.file.value = ''
            }
        }
    }
}
</script>