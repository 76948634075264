<template lang="pug">
    div
        filters
            template(v-slot:statistics)
                statistics
                    template(v-slot:total)
                        span {{ userPostsResponse.total }}
        user-posts-list
</template>

<script>
//Components
import Filters from '@/components/common/filters';
import Statistics from '@/components/common/filters/base-statistics';
import RegularButton from '@/components/common/buttons/regular-button';
import UserPostsList from '@/components/views/user-posts';
//Config
import { BUTTONS_VALUES } from '@/configs/names.js';
//Store
import { UserPostsNameSpace, UserPostsGetterTypes } from '@/store/user-posts/types';

export default {
    name: "UserPostsListPage",
    components: {
        'filters': Filters,
        'statistics': Statistics,
        'regular-button': RegularButton,
        'user-posts-list': UserPostsList,
    },
    computed: {
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        userPostsResponse() {
            return this.$store.getters[`${UserPostsNameSpace}/${UserPostsGetterTypes.GetUserPostsResponse}`]
        }
    },
}
</script>