<template lang="pug">
    div
        table.table-auto.border-collapse.border.border-slate-400.w-full.bg-white
            table-header(:list="USER_POSTS_LIST_TABLE_HEADERS")
            tbody
                template(v-for="(item, index) in user_posts")
                    tr(:key="item._id")             
                        td.border.border-slate-300.w-10.p-2.font-light.text-xs.text-center
                            span {{ index + 1 }}

                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1.min-w-100.max-w-150
                            span.block {{ item.lastname }}
                            span.block {{ item.firstname }}
                            span.block {{ item.patronymic }}

                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1.min-w-100.max-w-150
                            span {{ item.date_of_birth }}
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1.min-w-100.max-w-150
                            span {{ item.city }}
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1.min-w-100.max-w-150
                            span {{ item.phone }}
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1.max-w-200.cursor-pointer
                            span {{ item.title }}

                        td.align-top.text-sm.font-thin.border.border-slate-300.text-center.p-1.max-w-300.align-top
                            span.block.mb-2 {{ onText(item) }}                             
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1.min-w-100.max-w-150
                            template(v-for="(file, index) in item.attachment")
                                ul
                                    li.mb-2
                                        a(:href="`${base_url}/${file.path}`" target="_blank" class="hover:underline flex")
                                            img(:src="selectIcon({ file_type: file.type })" class="mr-1")
                                            span Файл: {{ index+1 }}
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.p-1.max-w-200.cursor-pointer
                            button.mb-2(@click="onDetailsPageOpen(item)" class="w-content text-xs p-2 border-1 rounded-md bg-gray-100 active:bg-gray-200") Подробнее
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.w-100
                            switcher-style-one.m-auto(
                                @on-switch="onLike(item)"
                                :status="isLiked(item) && +isLiked(item).status ? true : false"
                                :title="{on: SWITCHERS_VALUES.accepted, off: SWITCHERS_VALUES.not_accepted}"
                                activeColor="bg-green-500"
                            )
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.w-100
                            switcher-style-one.m-auto(
                                @on-switch="onStatusChange(item)"
                                :status="+item.status ? true : false"
                                :title="{on: SWITCHERS_VALUES.active, off: SWITCHERS_VALUES.blocked}"
                            )
                        
                        td.text-sm.font-thin.border.border-slate-300.text-center.text-gray-500.w-100 
                            inline-svg.m-auto(
                                :src="ICONS.bin" 
                                @click="() => { if (deleteFilter) {onDeleteConfirmation(item)} }"
                                :fill-opacity="deleteFilter ? 1 : 0.25"
                            )
        button(@click="onLoadMore") Загрузить еще
</template>

<script>
//Components
import SwitcherStyleOne from '@/components/common/switchers/style-1';
import TextArea from '@/components/common/forms/text-area';
import EditInputWrapper from '@/components/common/forms/edit-wrapper';
import TableHeader from '@/components/common/tables/table-header';
import ChooseFile from '@/components/common/forms/choose-file';
import RegularButton from '@/components/common/buttons/regular-button';
//Store
// import { FilesNameSpace, FilesActionTypes, FilesGetterTypes, FilesMutationTypes } from '@/store/files/types';
import { UserPostsNameSpace, UserPostsActionTypes, UserPostsGetterTypes, UserPostsMutationTypes } from '@/store/user-posts/types';
import { LikesNameSpace, LikesActionTypes } from '@/store/likes/types';
import { RootGetterTypes } from '@/store/types';
//libs
import moment from 'moment';
//Configs
import { USER_POSTS_LIST_TABLE_HEADERS, SWITCHERS_VALUES, BUTTONS_VALUES, FORMS_LABELS } from '@/configs/names.js';
import { ICONS } from '@/configs/icons';
//Mixins
import { deleteConfirmation, closeModalDialog, _openModal_, _closeModal_ } from '@/mixins/modals';
import { _onFileUpload_ } from '@/mixins/files';
//Helpers
import { getUserAuthData } from '@/helpers/authorization';
import { selectIcon } from '@/helpers/select-icon';
import { getFileType } from '@/helpers/get-file-type';

export default {
    name: "UserPostsComponent",
    mixins: [deleteConfirmation, closeModalDialog, _openModal_, _closeModal_, _onFileUpload_],
    components: {
        'switcher-style-one': SwitcherStyleOne,
        'text-area': TextArea,
        'edit-input-wrapper': EditInputWrapper,
        'table-header': TableHeader,
        'choose-file': ChooseFile,
        'regular-button': RegularButton,
    },
    data() {
        return {
            name_edit_id: '',
            name_edit_value: '',
            firstname_edit_id: '',
            firstname_edit_value: '',
            lastname_edit_id: '',
            lastname_edit_value: '',
            patronymic_edit_id: '',
            patronymic_edit_value: '',
            delete_user_post_id: null,
            photo: [],
            photo_edit_id: '',
            currentPage: 0,
            perPage: 50,
        }
    },
    computed: {
        ICONS() {
            return ICONS
        },
        SWITCHERS_VALUES() {
            return SWITCHERS_VALUES
        },
        BUTTONS_VALUES() {
            return BUTTONS_VALUES
        },
        FORMS_LABELS() {
            return FORMS_LABELS
        },
        USER_POSTS_LIST_TABLE_HEADERS() {
            return USER_POSTS_LIST_TABLE_HEADERS
        },
        user_posts() {
            return this.$store.getters[`${UserPostsNameSpace}/${UserPostsGetterTypes.GetUserPosts}`]
        },
        base_url() {
            return process.env.VUE_APP_BASE_URL
        },
        deleteFilter() {
            return this.$store.getters[`${RootGetterTypes.GetDeleteFilterStatus}`]
        },
    },
    methods: {
        selectIcon({ file_type }) {
            return selectIcon({ file_type })
        },
        getUserAuthData() {
            return getUserAuthData()
        },
        getFileType({ file_type }) {
            return getFileType({ file_type })
        },
        moment() {
            return moment()
        },
        onLoad({ per_page, page }) {            
            this.$store.dispatch(`${UserPostsNameSpace}/${UserPostsActionTypes.GetUserPosts}`, {params: { per_page, page }})
        },
        onLoadMore() {
            this.currentPage = this.currentPage + 1
            this.onLoad({ per_page: this.perPage, page: this.currentPage })
        },
        onStatusChange({ _id, status }) {
            let status_value = Number(status)
            status_value = !status_value
            this.onUserPostChange({ id: _id, value: {status: status_value} })
        },
        async onUserPostChange({ id, value }) {
            await this.$store.dispatch(`${UserPostsNameSpace}/${UserPostsActionTypes.EditUserPost}`, { id, value })
        },
        async onLike({ _id, likes }) {
            const likeValue = this.isLiked({ _id, likes })
            if (likeValue) {
                await this.$store.dispatch(`${LikesNameSpace}/${LikesActionTypes.EditLike}`, {
                    id: this.isLiked({ _id, likes })._id,
                    params: { 
                        status: +likeValue.status !== 0 ? 0 : 1 
                    }
                })

                this.onUserPostChange( { id: _id, value: {} } )
            } else {
                const { data } = await this.$store.dispatch(`${LikesNameSpace}/${LikesActionTypes.AddLike}`, {
                    params: { 
                        user_id: this.getUserAuthData().id, 
                        reference: _id,
                        referenceModel: 'UserPost', 
                        status: 1 
                    }
                })

                this.onUserPostChange( { id: _id, value: {likes: data._id} } )
            }
        },
        isLiked({ _id, likes }) {
            const result = likes.find(item => {
               if ((item.reference._id === _id) && (item.user_id._id === this.getUserAuthData().id)) {
                    return item
               }
            })
            return result
        },
        onText({ text }) {
            if (text.length > 100) {
                return text.substring(0, 100) + '...'
            } else {
                return text
            }
        },
        async onDeleteConfirmation({_id}) {
            this.delete_user_post_id = _id
            this.deleteConfirmation({ confirm: 'onDeleteUserPost' })
            
        },
        async onDeleteUserPost() {
            await this.$store.dispatch(`${UserPostsNameSpace}/${UserPostsActionTypes.DeleteUserPost}`, { id: this.delete_user_post_id }) 
            this.closeModalDialog()
            this.delete_user_post_id = null
        },
        onDetailsPageOpen({ _id }) {
            this.$router.push(`${_id}`)
        }
    },
    mounted() {
        this.onLoad({ per_page: this.perPage, page: this.currentPage })
    },
    beforeDestroy() {
        this.$store.commit(`${UserPostsNameSpace}/${UserPostsMutationTypes.SetEmptyUserPost}`)
    }
}
</script>