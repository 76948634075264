import { ICONS } from '@/configs/icons';

const selectIcon = ({ file_type }) => {
    if (file_type.split('/')[0] === 'video') {
        return ICONS.file_video
    }
    if (file_type.split('/')[0] === 'image') {
        return ICONS.file_picture
    }
}

export { selectIcon }